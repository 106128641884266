import React from "react";
import styled from "styled-components";
import i18n from "i18next";
import { connect } from "react-redux";
import PrimaryButton from "components/button/PrimaryButton";
import {
  accountPlanSelector,
  SUBSCRIPTION_PLANS,
  getUserAPIKeys,
  createUserAPIKey,
  showToastTip,
  deleteUserAPIKey,
  isAppInWhiteLabelMode,
  isAppInViewMode
} from "@kubera/common";

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`;
const Header = styled.div`
  font-weight: 700;
  font-size: 22px;
  line-height: 130%;
  font-feature-settings: "ss01" on;
  margin-bottom: 26px;
`;

const Desc = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;

  a {
    color: black;
  }
`;

const GenerateKeyButton = styled(PrimaryButton)`
  width: 196px;
  height: 44px;
`;

const APIKeyContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 30px;
`;

const DeleteButton = styled.a`
  font-size: 11px;
  font-weight: 400;
  line-height: 16.5px;
  text-align: left;
  font-feature-settings: "ss01" on;
  color: ${props => props.theme.linkColor} !important;
  position: absolute;
  margin-left: 12px;
  text-decoration: underline;
  cursor: pointer;
`;

const AllowedIPText = styled.p`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  text-align: left;
  font-feature-settings: "ss01" on;
  color: black;
  opacity: 0.5;
  margin-top: -3px;
`;

class APIAccess extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isGenerateKeyLoading: false,
      apiKeys: [],
      error: null,
      fetchingKey: false
    };
    this.createAPIKey = this.createAPIKey.bind(this);
    this.showErrorToast = this.showErrorToast.bind(this);
    this.deleteAPIKey = this.deleteAPIKey.bind(this);
    this.createAPIKey = this.createAPIKey.bind(this);
  }

  showErrorToast(error) {
    this.props.showToastTip("TIP", `${i18n.t("genericExceptionTitle")}`, null, 5000);
  }

  componentDidMount() {
    if (this.isBlack()) this.getAPIKeys();
  }

  getAPIKeys() {
    this.setState({ fetchingKey: true });
    getUserAPIKeys()
      .then(data => this.setState({ apiKeys: data }))
      .catch(this.showErrorToast)
      .finally(() => this.setState({ fetchingKey: false }));
  } // do this in parent

  isBlack() {
    return !isAppInWhiteLabelMode() && !isAppInViewMode() && this.props.accountPlan === SUBSCRIPTION_PLANS.YEARLY_BLACK;
  }

  createAPIKey() {
    this.setState({ isGenerateKeyLoading: true });
    createUserAPIKey(`API Key ${this.state.apiKeys.length + 1}`)
      .then(data => this.setState({ apiKeys: [data, ...this.state.apiKeys] }))
      .catch(this.showErrorToast)
      .finally(() => this.setState({ isGenerateKeyLoading: false }));
  }

  deleteAPIKey(key) {
    deleteUserAPIKey(key)
      .then(() => {
        this.getAPIKeys();
      })
      .catch(this.showErrorToast);
  }

  render() {
    return (
      <Container className={this.props.className}>
        <Header>{i18n.t("accountSettings.apiAccessTitle")}</Header>
        <Desc
          dangerouslySetInnerHTML={{
            __html: i18n.t("accountSetting.apiAccessDescTop")
          }}
        />
        {!this.isBlack() && <br />}
        {this.isBlack() && (
          <APIKeyContainer>
            {!this.state.fetchingKey && this.state.apiKeys.length === 0 && (
              <GenerateKeyButton
                title={i18n.t("generateAPIKey")}
                data-cy="generateAPIKeyButton"
                onClick={this.createAPIKey}
                isLoading={this.state.isGenerateKeyLoading}
              />
            )}
            {this.state.apiKeys.map(key => (
              <div key={key.id}>
                <Desc>
                  <strong data-private>
                    {key.name}: {key.id}
                  </strong>
                  <DeleteButton onClick={() => this.deleteAPIKey(key.id)}>{i18n.t("DELETE")}</DeleteButton>
                </Desc>
                {!!key.allowedIp && (
                  <AllowedIPText>
                    {i18n.t("IP Address")}: {key.allowedIp}
                  </AllowedIPText>
                )}
              </div>
            ))}
          </APIKeyContainer>
        )}
        <Desc
          dangerouslySetInnerHTML={{
            __html: i18n.t("accountSetting.apiAccessDescBottom")
          }}
        />
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  accountPlan: accountPlanSelector(state)
});

const mapDispatchToProps = {
  showToastTip: showToastTip
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(APIAccess);
